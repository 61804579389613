import { isMac } from '@blissbook/ui/lib/platform'

const shortcutsByKey: Record<string, string> = {
  alt: '⇧',
  mod: isMac ? '⌘' : 'Ctrl',
  shift: '⌥',
}

const renderKeyboardShortcut = (keyboardShortcut: string) => {
  const keys = keyboardShortcut
    .split('-')
    .map((key) => shortcutsByKey[key.toLowerCase()] || key.toUpperCase())

  const joinChar = keys.some((key) => key.length > 1) ? '+' : ''
  return keys.join(joinChar)
}

export const renderToolbarTooltip = (
  label: string,
  keyboardShortcut?: string,
) => {
  if (!keyboardShortcut) return label
  const shortcut = renderKeyboardShortcut(keyboardShortcut)
  return `${label} (${shortcut})`
}
