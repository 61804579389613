import {
  type AudienceRootExpression,
  mapLogicalExpressionStringToAudienceExpression,
} from '@blissbook/lib/expression'
import type { NodeWithPos, Range } from '@tiptap/core'
import type { Node as ProseMirrorNode, ResolvedPos } from 'prosemirror-model'
import type { Attrs } from 'prosemirror-model'
import { v4 as uuidV4 } from 'uuid'
import { containerNodeTypes } from './container'
import { listNodeTypes } from './list'
import type { Node } from './node'

export const blockExpressionNodeTypes = [
  'heading',
  'horizontalRule',
  'listItem',
  'paragraph',
  'pdf',
  'table',
  'video',
]

export const expressionNodeTypes = [
  ...blockExpressionNodeTypes,
  ...containerNodeTypes,
]

export type ExpressionNode = NodeWithPos & {
  audienceExpression?: AudienceRootExpression
  expression?: string
  groupId: string
  parent?: ProseMirrorNode
}

function isExpressionEqual(lhs: ProseMirrorNode, rhs: ProseMirrorNode) {
  const lhsExpression = getAudienceExpressionFromNodeAttrs(lhs.attrs)
  const rhsExpression = getAudienceExpressionFromNodeAttrs(rhs.attrs)
  return (
    !!lhsExpression &&
    !!rhsExpression &&
    JSON.stringify(lhsExpression) === JSON.stringify(rhsExpression)
  )
}

function getParentNode($pos: ResolvedPos) {
  for (let depth = $pos.depth; depth > 0; depth--) {
    const ancestor = $pos.node(depth)
    if (containerNodeTypes.includes(ancestor.type.name)) {
      return ancestor
    }
  }
}

export function getExpressionNodes(doc: ProseMirrorNode, range?: Range) {
  let lastBlockNode: ExpressionNode
  const blockNodes: ExpressionNode[] = []
  const containerNodes: ExpressionNode[] = []
  const nodes: ExpressionNode[] = []

  doc.descendants((node, pos) => {
    const from = pos
    const to = from + node.nodeSize
    const isInRange = !range || (to > range.from && from < range.to)

    const type = node.type.name
    if (isInRange && blockExpressionNodeTypes.includes(type)) {
      const audienceExpression = getAudienceExpressionFromNodeAttrs(node.attrs)
      const $pos = doc.resolve(pos)
      const parent = getParentNode($pos)
      const groupId =
        lastBlockNode &&
        isExpressionEqual(node, lastBlockNode.node) &&
        parent === lastBlockNode.parent
          ? lastBlockNode.groupId
          : uuidV4()

      lastBlockNode = { audienceExpression, groupId, node, parent, pos }
      blockNodes.push(lastBlockNode)
      nodes.push(lastBlockNode)
      return false
    }

    if (listNodeTypes.includes(type)) {
      // Allow to go deeper
    } else if (containerNodeTypes.includes(type)) {
      lastBlockNode = undefined

      const audienceExpression = getAudienceExpressionFromNodeAttrs(node.attrs)
      const $pos = doc.resolve(pos)
      const parent = getParentNode($pos)
      const groupId = uuidV4()
      const containerNode = { audienceExpression, groupId, node, parent, pos }
      containerNodes.push(containerNode)
      nodes.push(containerNode)
    } else {
      lastBlockNode = undefined
      return false
    }
  })

  return { blockNodes, containerNodes, nodes }
}

/**
 * Pull out the audience expression from these node attrs
 * use DEPRECATED expression, if audienceExpression is not available */
export function getAudienceExpressionFromNodeAttrs(
  attrs: Attrs,
): AudienceRootExpression | undefined {
  const { audienceExpression, expression } = attrs

  if (audienceExpression) {
    return audienceExpression
  }
  // Convert DEPRECATED expression?
  if (expression) {
    return mapLogicalExpressionStringToAudienceExpression(expression)
  }
}

/**
 * Pull out the audience expression from this node
 * use DEPRECATED expression, if audienceExpression is not available */
export function getAudienceExpressionFromNode(
  node: Node,
): AudienceRootExpression | undefined {
  if ('attrs' in node) {
    return getAudienceExpressionFromNodeAttrs(node.attrs)
  }
}
