import { cx } from '@emotion/css'
import { LayoutGroup, motion } from 'framer-motion'
import React, { type CSSProperties, type ReactNode, useRef } from 'react'

export type RadioSliderOption = {
  Icon?: (props: { className?: string }) => JSX.Element
  label: string
}

export type RadioSliderProps = {
  className?: string
  disabled?: boolean
  onChange?: (value: number) => void
  options: RadioSliderOption[]
  style?: CSSProperties
  value: number
}

export function RadioSlider({
  className,
  disabled,
  onChange,
  options,
  value,
  ...props
}: RadioSliderProps) {
  const step = 100 / options.length
  return (
    <div
      className={cx('tw-bg-gray-100 tw-relative tw-rounded', className)}
      {...props}
    >
      <div className='tw-flex'>
        {options.map((option, index) => (
          <button
            className='tw-flex tw-items-center tw-justify-center tw-gap-2 tw-flex-1 tw-py-0.5 tw-rounded tw-transition-all tw-text-transparent hover:tw-bg-gray-200'
            disabled={disabled}
            key={index}
            onClick={() => {
              onChange(index)
            }}
            type='button'
          >
            {option.Icon && <option.Icon className='tw-text-xs tw-opacity-0' />}
            {option.label}
          </button>
        ))}
      </div>
      <div
        className='tw-absolute tw-bg-white tw-border tw-border-gray-100 tw-duration-500 tw-pointer-events-none tw-rounded tw-shadow tw-transition-all'
        style={{
          bottom: 0,
          left: step * value + '%',
          top: 0,
          width: step + '%',
        }}
      />
      <div className='tw-absolute tw-flex tw-items-center tw-left-0 tw-pointer-events-none tw-py-0.5 tw-right-0 tw-text-center tw-top-0 tw-h-full'>
        {options.map((option, index) => (
          <div
            className='tw-flex tw-items-center tw-justify-center tw-gap-2 tw-flex-1'
            key={index}
          >
            {option.Icon && <option.Icon className='tw-text-xs' />}
            {option.label}
          </div>
        ))}
      </div>
    </div>
  )
}

export type RadioSliderTab = {
  label: string
  content: ReactNode
}

export type RadioSliderTabsProps = {
  className?: string
  disabled?: boolean
  onChange?: (value: number) => void
  options: RadioSliderTab[]
  style?: CSSProperties
  value: number
}

export function RadioSliderTabs({
  disabled,
  onChange,
  options,
  value,
  ...props
}: RadioSliderTabsProps) {
  const firstRenderRef = useRef(true)
  const firstRender = firstRenderRef.current
  firstRenderRef.current = false
  return (
    <div {...props}>
      <RadioSlider
        className='tw-mb-4'
        disabled={disabled}
        onChange={onChange}
        options={options}
        value={value}
      />

      <LayoutGroup>
        {options.map((option, index) => {
          const active = index === value
          if (!active) return null

          return (
            <motion.div
              key={index}
              animate={{ opacity: 1 }}
              initial={firstRender ? undefined : { opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              {option.content}
            </motion.div>
          )
        })}
      </LayoutGroup>
    </div>
  )
}
